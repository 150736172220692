// Lens Module
import LensAPI from '@/api/lens'
import Fuse from 'fuse.js'
export default {
  namespaced: true,
  state: {
    fuse: null,
    fuzzyResults: new Set(),
    metricSearch: '',

    metrics: {},
    metricLookup: {}
  },
  getters: {
    getFuzzyResults: state => state.fuzzyResults,
    getMetrics: state => state.metrics,
    getMetricSearch: state => state.metricSearch,
    getMetricLookup: state => state.metricLookup,
    getFuse: state => state.fuse
  },
  mutations: {
    SET_FUZZY_RESULTS (state, results) {
      state.fuzzyResults = results
    },
    SET_METRIC_SEARCH (state, text) {
      state.metricSearch = text
    },
    SET_METRIC_LOOKUP (state, lookup) {
      state.metricLookup = { ...lookup }
    },
    SET_LENS_METRICS (state, metrics) {
      state.metrics = metrics
    },
    SET_FUSE (state, fuse) {
      state.fuse = fuse
    }
  },
  actions: {
    setFuzzyResults ({ commit }, results) {
      commit('SET_FUZZY_RESULTS', results)
    },
    updateTextSearch ({ commit }, text) {
      commit('SET_METRIC_SEARCH', text)
    },
    async fetchLensMetrics ({ state, commit }) {
      console.log('Fetching Lens Metrics')
      const { data } = await LensAPI.LensMetrics.getLensMetrics()

      const metrics = {}
      const metricLookup = {}
      // TODO @Sam: Add metricPlatform column and group off platform (meta, ga, etc..)
      data.forEach(metric => {
        // Populate metric lookup (map used for quick lookups)
        metricLookup[metric.key] = metric

        if (!metrics?.[metric.parent_group]) {
          metrics[metric.parent_group] = {
            sectionId: metric.parent_group,
            metrics: [],
            subSections: []
          }
        }
        if (metric.child_group) {
          const currentSubSection = metrics[metric.parent_group].subSections.find(sub => sub.sectionId === metric.child_group)
          // Init subsection
          if (!currentSubSection) {
            metrics[metric.parent_group].subSections.push({
              sectionId: metric.child_group,
              domId: metric.child_group,
              metrics: [metric]
            })
          } else {
            currentSubSection.metrics.push(metric)
          }
        } else {
          metrics[metric.parent_group].metrics.push(metric)
        }
      })
      // Initialize Fuse searcher & metric lookup
      if (!state.fuse) {
        const allMetrics = data
        const options = {
          includeScore: true,
          keys: ['key'],
          ignoreLocation: true,
          threshold: 0.3
        }
        const fuse = new Fuse(allMetrics, options)
        commit('SET_FUSE', fuse)
        commit('SET_METRIC_LOOKUP', metricLookup)
      }
      commit('SET_LENS_METRICS', metrics)
    }
  }
}
