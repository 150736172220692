// Imports
import AxiosConfig from './LensAxiosConfig'
import firebase from '../config/FirebaseConfig'

// Fetch AD
const getLensGroups = async (lensId, query) => {
  const { Aa: token } = firebase.auth().currentUser

  const { group, page, limit, sort, selectedRows, filters } = query

  const payload = {
    metadata: {
      group_by: group || 'ad_name',
      ...(page && { page }),
      ...(limit && { limit }),
      ...(sort && { sort }),
      ...(selectedRows && { selected_rows: selectedRows })
    },
    query: filters || []
  }

  const { data } = await AxiosConfig.post(
    `/ads/group/${lensId}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return data
}

export default {
  getLensGroups
}
