<template>
  <div class="relative w-full h-9">
    <div class="multi-select w-full flex flex-col rounded-lg bg-white"
    v-on-clickaway="() => { if (isExpanded) isExpanded = false }">
      <!-- Opener -->
      <button class="flex items-center gap-3 px-2 py-1.5 focus:outline-none" 
      @click="isExpanded = !isExpanded" @focus="isExpanded = true" @mousedown.prevent>
        <!-- Icon -->
        <component v-if="isComponentIcon(icon)" :is="icon" :width="20" :height="20" class="text-icon-normal flex-shrink-0" />
        <img v-else-if="icon" :src="icon" class="w-5 h-5 flex-shrink-0" />
        <!-- Selected Items list -->
        <div ref="selected-list" class="flex-grow flex items-center gap-1">
          <BaseText v-if="!visibleSelections.length" type="body" size="sm" class="text-text-normal py-0.5 opacity-90">
            {{ emptyLabel }}
          </BaseText>
          <div v-for="selection in visibleSelections" :key="`selection-${selection.id}`"
          class="flex items-center gap-1 pl-1.5 py-0.5 pr-0.5 bg-neutral-25" style="border-radius: 4px">
            <BaseText type="label" size="xs" class="text-text-muted truncate" style="max-width: 120px">
              {{ selection.name }}
            </BaseText>
            <button class="p-0.5 transition-colors duration-100 hover:bg-neutral-50" style="border-radius: 3px"
            @click.stop="() => { removeSelection(selection.id) }">
              <TagRemoveIcon :width="16" :height="16" stroke="#5E6678" />
            </button>
          </div>
          <!-- '+x' hidden selections indicator -->
          <div v-if="hiddenSelections.length" class="relative px-1.5 py-1 transition-colors cursor-default" 
          style="border-radius: 4px" :class="hiddenIndicatorHovered ? 'bg-neutral-50' : 'bg-neutral-25'"
          @mouseenter="hiddenIndicatorHovered = true" @mouseleave="hiddenIndicatorHovered = false">
            <BaseText type="label" size="xs" class="text-text-muted">
              +{{ hiddenSelections.length }}
            </BaseText>
            <!-- hover tooltip -->
            <transition name="fade">
              <div v-if="hiddenIndicatorHovered" class="hidden-indicator-tooltip pb-1.5">
                <div class="flex flex-col gap-1.5 pl-2.5 pr-1 py-1.5 rounded-md bg-neutral-malpha-25 shadow-sm">
                  <div v-for="selection in hiddenSelections" :key="`hidden-selection-${selection.id}`"
                  class="flex items-center gap-1.5 min-w-0">
                    <BaseText type="label" size="xs" class="text-white truncate" style="max-width: 120px">
                      {{ selection.name }}
                    </BaseText>
                    <button class="p-0.5 ml-auto transition-colors duration-100 hover:bg-neutral-malpha-100" 
                    style="border-radius: 3px" @click.stop="() => { removeSelection(selection.id) }">
                      <TagRemoveIcon :width="16" :height="16" stroke="#A1A2A5" />
                    </button>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <!-- Chevron -->
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
        class="flex-shrink-0 text-icon-normal transform transition-transform" :style="{transform: isExpanded ? 'scaleY(-1)' : 'scaleY(1)'}">
          <path d="M13.25 8.75L10 12.25L6.75 8.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <!-- Dropdown -->
      <div class="dropdown relative w-full rounded-b-lg overflow-hidden" :style="{height: isExpanded ? `${dropdownHeight}px` : '0px'}">
        <div ref="dropdown" class="w-full h-full min-h-0 flex flex-col gap-0.5 p-1 border-t border-border-normal overflow-y-scroll scrollbar-hide">
          <button v-for="(item, index) in items" :key="`item-${item.id}`" :ref="`dropdown-item-${index}`"
          class="w-full flex items-center gap-3 rounded-md min-w-0 p-1 text-left transition-colors duration-100" 
          :class="[navIndex === index ? 'bg-neutral-25' : !pointerEventsPaused ? 'hover:bg-neutral-25' : '', {'pointer-events-none': pointerEventsPaused}]"
          @click="toggleSelection(item.id)" @mouseenter="() => { if (navIndex !== null) navIndex = null }">
            <component v-if="isComponentIcon(item.icon)" :is="item.icon" :width="20" :height="20" class="text-icon-normal flex-shrink-0" />
            <img v-else-if="item.icon" :src="item.icon" class="w-5 h-5 flex-shrink-0" />
            <BaseText type="label" size="sm" class="flex-grow text-text-muted flex-shrink-0 truncate">
              {{ item.name }}
            </BaseText>
            <NewCheckmarkIcon v-if="isItemSelected(item.id)" class="text-icon-normal" />
          </button>
        </div>
        <!-- bottom fade overlay -->
        <transition name="fade-overlay">
          <div v-if="isExpanded && listOverflows && !isMaxScroll" class="bottom-fade-overlay rounded-b-lg" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import TagRemoveIcon from './Icons/TagRemoveIcon.vue'
import NewCheckmarkIcon from './Icons/NewCheckmarkIcon.vue'

export default {
  name: 'BaseMultiSelect',
  mixins: [clickaway],
  components: {
    TagRemoveIcon,
    NewCheckmarkIcon
  },
  props: {
    // An array of objects. Each item needs an 'id' and 'name' property.
    // 'icon' property is optional - can be either an SVG component or image URL.
    items: {
      type: Array,
      required: true
    },
    // An array of ids corresponding to items in the 'items' prop. Synced with parent.
    selections: {
      type: Array,
      required: true
    },
    // Can be an SVG component or an image URL
    icon: {
      type: [Object, String],
      default: undefined
    },
    // Text to display when no selections are made
    emptyLabel: {
      type: String,
      default: ''
    },
    // Maximum height of the dropdown when expanded
    maxDropdownHeight: {
      type: Number,
      default: 235
    }
  },
  data () {
    // UI states
    return {
      isExpanded: false,
      visibleSelections: [],
      hiddenSelections: [],
      hiddenIndicatorHovered: false,
      dropdownHeight: 0,
      listOverflows: false,
      isMaxScroll: false,
      pointerEventsPaused: false,
      navIndex: null
    }
  },
  watch: {
    selections () {
      this.computeVisibleSelections()
    },
    isExpanded (expanded) {
      if (expanded) {
        window.addEventListener('keydown', this.handleKeyboardNavigation)
        this.SET_PREVENT_DRAWER_ESCAPE_KEY(true)
      } else {
        window.removeEventListener('keydown', this.handleKeyboardNavigation)
        this.navIndex = null
        this.SET_PREVENT_DRAWER_ESCAPE_KEY(false)
      }
    }
  },
  computed: {
    selectedItems () {
      return this.items.filter(item => this.selections.includes(item.id))
    }
  },
  mounted () {
    let dropdownHeight = Math.min(this.items.length * 30 + 8, this.maxDropdownHeight)
    this.dropdownHeight = dropdownHeight
    this.$nextTick(() => {
      this.computeVisibleSelections()
      this.determineListOverflows(dropdownHeight)
      this.$refs.dropdown.addEventListener('scroll', this.handleDropdownScroll)
    })
  },
  beforeDestroy () {
    this.SET_PREVENT_DRAWER_ESCAPE_KEY(false)
    window.removeEventListener('keydown', this.handleKeyboardNavigation)
  },
  methods: {
    ...mapMutations('MiscModule', ['SET_PREVENT_DRAWER_ESCAPE_KEY']),
    isItemSelected (itemId) {
      return this.selections.some(id => id === itemId)
    },
    toggleSelection (itemId) {
      if (this.isItemSelected(itemId)) {
        this.removeSelection(itemId)
      } else {
        this.addSelection(itemId)
      }
    },
    addSelection (itemId) {
      const updatedSelections = [...this.selections, itemId]
      this.$emit('update:selections', updatedSelections)
    },
    removeSelection (itemId) {
      const updatedSelections = this.selections.filter(id => id !== itemId)
      this.$emit('update:selections', updatedSelections)
    },

    // ========= UI Methods =========

    isComponentIcon (icon) {
      return typeof icon === 'object'
    },
    determineListOverflows (dropdownHeight) {
      const dropdown = this.$refs['dropdown']
      if (!dropdown) return
      this.listOverflows = dropdown.scrollHeight > dropdownHeight
    },
    handleDropdownScroll (event) {
      const dropdown = event.target
      if (!dropdown) return
      this.isMaxScroll = Math.abs(dropdown.clientHeight - (dropdown.scrollHeight - dropdown.scrollTop)) <= 2 
    },
    computeVisibleSelections () {
      // Compute how many selections are visible in the dropdown opener
      const containerWidth = this.$refs['selected-list']?.getBoundingClientRect()?.width - 32 // -32px for '+x'
      if (!containerWidth) return
      let totalWidth = 0
      let visibleSelections = []
      for (const selection of this.selectedItems) {
        const compNode = document.createElement('span')
        compNode.classList.add('pl-1.5', 'pr-0.5', 'truncate', 'text-label-xs', 'opacity-0', 'pointer-events-none')
        compNode.style.maxWidth = '120px'
        compNode.textContent = selection.name
        document.body.appendChild(compNode)
        const boardWidth = compNode.getBoundingClientRect().width + 24 // +20px for 'x' button
        document.body.removeChild(compNode)
        if (totalWidth + boardWidth + 4 < containerWidth) {
          totalWidth += boardWidth + 4 // +4px for gap
          visibleSelections.push(selection)
        } else {
          break
        }
      }
      this.visibleSelections = visibleSelections
      this.hiddenSelections = this.selectedItems.filter(selection => !visibleSelections.includes(selection))
    },

    // ========= Keyboard Navigation =========

    handleKeyboardNavigation (event) {
      const validKeys = ['ArrowUp', 'ArrowDown', 'Enter', 'Escape', 'Tab']
      if (!validKeys.includes(event.key)) return
      if (event.key === "Tab") {
        this.isExpanded = false
        return
      }
      event.preventDefault()
      if (!this.pointerEventsPaused && ['ArrowDown', 'ArrowUp'].includes(event.key)) {
        this.pauseItemPointerEvents()
      }
      switch (event.key) {
        case "ArrowDown":
          this.navigateNext(); break
        case "ArrowUp":
          this.navigatePrev(); break
        case "Enter":
          this.selectNavItem(); break
        case "Escape":
          this.isExpanded = false; break
      }
    },
    navigateNext () {
      if (this.navIndex === null || this.navIndex < this.items.length - 1) {
        this.navIndex = this.navIndex === null ? 0 : this.navIndex + 1
        this.scrollToItem(`dropdown-item-${this.navIndex}`)
      }
    },
    navigatePrev () {
      if (this.navIndex === 0) {
        this.navIndex = null
      } else if (this.navIndex !== null) {
        this.navIndex--
        this.scrollToItem(`dropdown-item-${this.navIndex}`)
      }
    },
    selectNavItem () {
      if (this.navIndex === null) return
      this.toggleSelection(this.items[this.navIndex].id)
    },
    scrollToItem (ref) {
      this.$nextTick(() => {
        let item = this.$refs[ref]?.[0]
        if (!item) return
        const itemRect = item.getBoundingClientRect()
        const container = this.$refs.dropdown
        const containerRect = container.getBoundingClientRect()

        let scrollPosition = container.scrollTop
        const offset = 20
        const overflowTop = containerRect.top - itemRect.top
        const overflowBottom = itemRect.bottom - containerRect.bottom
        if (this.navIndex === 0) {
          scrollPosition = 0
        } else if (this.navIndex === this.items.length - 1) {
          scrollPosition = container.scrollHeight
        } else if (overflowTop > 0) {
          scrollPosition = container.scrollTop - overflowTop - offset
        } else if (overflowBottom > 0) {
          scrollPosition = container.scrollTop + overflowBottom + offset
        }
        container.scrollTo({ top: scrollPosition, behavior: 'smooth' })
      })
    },
    pauseItemPointerEvents () {
      this.pointerEventsPaused = true
      let initialMouseX, initialMouseY
      const trackMouseMovement = (event) => {
        if (!initialMouseX || !initialMouseY) {
          initialMouseX = event.clientX
          initialMouseY = event.clientY
          return
        }
        const deltaX = Math.abs(event.clientX - initialMouseX)
        const deltaY = Math.abs(event.clientY - initialMouseY)
        if (deltaX >= 4 || deltaY >= 4) {
          this.pointerEventsPaused = false
          window.removeEventListener('mousemove', trackMouseMovement)
        }
      }
      window.addEventListener('mousemove', trackMouseMovement)
    }
  }
}
</script>

<style scoped>
.multi-select {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.hidden-indicator-tooltip {
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  max-width: 250px;
}
.dropdown {
  transition: height 100ms ease-in-out;
}
.bottom-fade-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
  background: linear-gradient(to top, white, transparent);
  transform-origin: bottom center;
  pointer-events: none;
}

/* ================= Vue <transition> classes ================= */
.fade-enter-active, .fade-leave-active {
  transition: opacity 150ms ease-in-out;
}
.fade-enter-from, .fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
.fade-overlay-enter-active, .fade-overlay-leave-active {
  transition: transform 75ms ease-in-out, opacity 75ms ease-in-out;
}
.fade-overlay-enter-from, .fade-overlay-enter, .fade-overlay-leave-to {
  transform: scaleY(0);
  opacity: 0.2;
}
.fade-overlay-enter-to, .fade-overlay-leave-from {
  transform: scaleY(1);
  opacity: 1;
}
</style>